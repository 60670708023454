<template>
  <div>
    <img class="home_banner" :src="topImg" id="part1" />
    <div class="about_title_div">
      <p>实际案例——</p>
    </div>
    <div class="news_div">
      <el-row :gutter="10">
        <el-col
          :xs="12"
          :sm="12"
          :md="8"
          :lg="8"
          :xl="8"
          v-for="(item, index) in NewsList"
          :key="index"
        >
          <div class="news_item" @click="navto('/NewsDetail/id=' + item.id)">
            <div class="news_top">
              <img :src="item.cover" />
              <p class="title">{{ item.name }}</p>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      path: "part1",
      topImg:
        "https://xiecheng-imgs.oss-cn-hangzhou.aliyuncs.com/20220104/0f3fab7bbd224e3486b14c46288eea7e.jpg",
      NewsList: [
        {
          name: "",
          cover: "",
          simple: "",
          time: "",
        },
      ],
    };
  },
  watch: {
    $route(to, from) {
      if (to.path === "/News") {
        console.log(to.path);
        var param = this.$route.query.param;
        window.scrollTo(0, 0);
        // this.path = param;
        // console.log("param:" + param);
        // this.navtoPartPath(this.path);
      }
    },
  },
  components: {},
  mounted() {
    this.getNews();
    window.scrollTo(0, 0);
  },
  methods: {
    navtoPartPath(path) {
      setTimeout(() => {
        document.getElementById(path).scrollIntoView();
      }, 500);
    },
    getNews() {
      let that = this;
      this.$axios
        .get("app/case/list", {
          params: {
            page: 0,
            limit: 1000,
          },
        })
        .then(function (response) {
          console.log(response);
          var status = response.data.code;
          if (status === 0) {
            that.NewsList = response.data.page.list;
            console.log(that.NewsList);
            // sessionStorage.setItem("input", "null");
          }
          // console.log("that.List");
        })
        .catch((err) => console.log(err));
    },
    navto(path) {
      this.$router.push(path);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped>
.home_banner {
  display: block;
  width: 100%;
  object-fit: cover;
}

.about_title_div {
  margin: -10rem 0 10rem 0;
  position: absolute;
  width: 40vw;
  background-color: var(--topTitleBlue);

  p {
    padding: 5rem 0 5rem 15rem;
    color: white;
    font-size: 6.4rem;
    font-family: bold;
    margin: 0;
    text-align: left;
    letter-spacing: 1rem;
  }
}

.news_div {
  background: white;
  padding: 12rem 0;
  width: 90%;
  margin: auto;
}

.news_item {
  display: flex;
  justify-content: space-evenly;
  width: 80%;
  margin: 10rem auto 5rem;
  transition: all 0.4s;

  .news_top {
    width: 46rem;
    height: 34rem;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .title {
      margin: -4rem 0 10rem 0;
      position: absolute;
      right: 0;
      background: var(--mainColor);
      color: white;
      font-family: regular;
      padding: 2rem 5rem;
      letter-spacing: 2px;
    }
  }
}

.news_item:hover {
  // box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.4);
  transform: translateY(-1rem);
  transition: all 0.4s;
}

@media screen and (max-width: 1000px) {
  .home_banner {
    height: 45vw;
    object-fit: cover;
  }

  .about_title_div {
    width: auto;
    margin: -5rem 0 10rem 0;

    p {
      padding: 2rem 4rem;
      font-size: 4rem;
    }
  }

  .news_div {
    width: 90%;
  }

  .news_item {
    width: 100%;
    margin:1rem 1rem 4rem;
    .news_top {
      width: 100%;
      height: 20rem;
    }
  }
}
</style>
