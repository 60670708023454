<template>
  <div>
    <el-container>
      <el-container>
        <el-header class="pc_header">
          <div class="logo_div" @click="navto('/')">
            <img src="../../static/img/logo.png" />
          </div>
          <!-- :default-active="activeIndex.toString()" -->
          <el-menu
            :default-active="activeIndex.toString()"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
          >
            <el-menu-item index="1" @click="scrollNavto('/', 'top')"
              >&emsp;首&ensp;页&emsp;</el-menu-item
            >
            <el-menu-item index="2" @click="scrollNavto('/About', 'top')"
              >关于我们</el-menu-item
            >
            <el-menu-item index="3" @click="scrollNavto('/Case', 'top')"
              >产品中心</el-menu-item
            >

            <el-menu-item index="5" @click="scrollNavto('/News', 'part1')"
              >实际案例</el-menu-item
            >
            <el-menu-item index="4" @click="scrollNavto('/Brand', 'part1')"
              >服务承诺</el-menu-item
            >
            <el-menu-item index="6" @click="scrollNavto('/CallUs', 'part1')"
              >联系我们</el-menu-item
            >

            <!-- <div class="phone_div">
              <img src="../../static/img/top_phone_icon.png" />
              <p>4006-879-123</p>
            </div> -->
          </el-menu>
        </el-header>
        <el-header class="phone_header">
          <div class="logo_div" @click="navto('/')">
            <img src="../../static/img/logo.png" />
          </div>
          <div class="phone_mune_div" @click="showSlideMenu()">
            <img src="../../static/img/汉堡栏.png" />
          </div>
          <el-drawer
            class="phone_slide_mune"
            title="我是标题必须设置"
            :modal="false"
            :show-close="false"
            :with-header="false"
            :visible.sync="drawer"
            :direction="direction"
            :before-close="handleClose"
            :size="drawerWidth"
          >
            <el-menu
              :default-active="activeIndex.toString()"
              class="el-menu-vertical-demo slide_mune"
              background-color="#115FADAA"
              text-color="#fff"
              active-text-color="#fff"
            >
              <el-menu-item index="1" @click="scrollNavto('/', 'top')"
                >&emsp;首页&emsp;</el-menu-item
              >
              <el-menu-item index="2" @click="scrollNavto('/About', 'top')"
                >关于我们</el-menu-item
              >
              <el-menu-item index="3" @click="scrollNavto('/Case', 'top')"
                >产品中心</el-menu-item
              >

              <el-menu-item index="5" @click="scrollNavto('/News', 'part1')"
                >实际案例</el-menu-item
              >
              <el-menu-item index="4" @click="scrollNavto('/Brand', 'part1')"
                >服务承诺</el-menu-item
              >
              <el-menu-item index="6" @click="scrollNavto('/CallUs', 'part1')"
                >联系我们</el-menu-item
              >
            </el-menu>
          </el-drawer>
        </el-header>
        <el-main>
          <keep-alive>
            <router-view :key="$route.fullpath"></router-view>
          </keep-alive>
        </el-main>
        <el-footer>
          <!-- <img class="color_img" src="../../static/img/渐变条.png" /> -->
          <div>
            <el-row :gutter="10" class="foot_nav">
              <el-col
                :xs="8"
                :sm="8"
                :md="2"
                :lg="2"
                :xl="2"
                class="phone_foot_gone"
                ><div class="grid-content">
                  <p class="foot_litter_line"></p>
                  <p class="foot_title" @click="scrollNavto('/', 'top')">
                    首&emsp;页&emsp;
                  </p>
                  <p>场景展示</p>
                  <p>应用领域</p>
                </div></el-col
              >
              <el-col
                :xs="8"
                :sm="8"
                :md="2"
                :lg="2"
                :xl="2"
                class="phone_foot_gone"
                ><div class="grid-content">
                  <p class="foot_litter_line"></p>
                  <p class="foot_title" @click="scrollNavto('/About', 'top')">
                    关于我们
                  </p>
                  <p>企业简介</p>
                  <p>企业文化</p>
                  <p>认证标志</p>
                </div></el-col
              >
              <el-col
                :xs="8"
                :sm="8"
                :md="2"
                :lg="2"
                :xl="2"
                class="phone_foot_gone"
                ><div class="grid-content">
                  <p class="foot_litter_line"></p>
                  <p class="foot_title" @click="scrollNavto('/Case', 'top')">
                    产品中心
                  </p>
                  <p>灭菌设备</p>
                  <p>清洗设备</p>
                  <p>灭菌辅助设备</p>
                </div></el-col
              >
              <el-col
                :xs="8"
                :sm="8"
                :md="2"
                :lg="2"
                :xl="2"
                class="phone_foot_gone"
                ><div class="grid-content">
                  <p class="foot_litter_line"></p>
                  <p class="foot_title" @click="scrollNavto('/News', 'part1')">
                    实际案例
                  </p>
                  <p>案例展示</p>
                </div></el-col
              >
              <el-col
                :xs="9"
                :sm="9"
                :md="2"
                :lg="2"
                :xl="2"
                class="phone_foot_gone"
                ><div class="grid-content">
                  <p class="foot_litter_line"></p>
                  <p class="foot_title" @click="scrollNavto('/Brand', 'part1')">
                    服务承诺
                  </p>
                  <p>售后保障</p>
                </div></el-col
              >

              <el-col
                :xs="7"
                :sm="7"
                :md="8"
                :lg="8"
                :xl="8"
                class="phone_foot_gone"
                ><div class="grid-content">
                  <p class="foot_litter_line"></p>
                  <p
                    class="foot_title"
                    @click="scrollNavto('/CallUs', 'part1')"
                  >
                    联系我们
                  </p>
                  <p>地址：浙江省宁波市海曙区横街镇环镇大道1号</p>
                  <p>电话：0574-88425999</p>
                  <p>邮箱：enquiry@yeson-medicine.com</p>
                  <p>邮编：315181</p>
                </div></el-col
              >
              <el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
                <div class="ercode_father_div">
                  <div class="grid-content ercode_div">
                    <img src="../../static/img/首页二维码3.png" />
                    <p>售 前 咨 询</p>
                  </div>
                  <div class="grid-content ercode_div">
                    <img src="../../static/img/首页二维码2.png" />
                    <p>微 信 视 频 号</p>
                  </div>
                  <div class="grid-content ercode_div">
                    <img src="../../static/img/首页二维码1.png" />
                    <p>公 众 号</p>
                  </div>
                </div>
              </el-col>
            </el-row>
            <!-- <hr class="foot_line" /> -->
          </div>
          <div class="beian_div">
            <a href="https://beian.miit.gov.cn/">备案号：浙ICP备17030255号-2</a>
            <p>版权所有 © 宁波市海曙协成医疗器械有限公司 保留一切权利</p>
          </div>
        </el-footer>
      </el-container>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: 0,
      drawer: false,
      drawerWidth: "70%",
      direction: "rtl",
    };
  },
  watch: {
    $route(to, from) {
      console.log("to==" + to.path);
      if (to.path === "/") {
        this.activeIndex = 1;
      } else if (to.path === "/About") {
        this.activeIndex = 2;
      } else if (to.path === "/Brand") {
        this.activeIndex = 4;
      } else if (to.path === "/Case" || to.path.includes("/CaseDetail")) {
        this.activeIndex = 3;
      } else if (to.path === "/News" || to.path.includes("/NewsDetail")) {
        this.activeIndex = 5;
      } else if (to.path === "/CallUs") {
        this.activeIndex = 6;
      }
    },
  },
  components: {},
  mounted() {
    this.Init();
  },
  methods: {
    Init() {},
    navto(path) {
      this.drawer = false;
      this.$router.push(path);
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    scrollNavto(path, name) {
      if (path === "/") {
        this.activeIndex = 1;
      }
      this.drawer = false;
      this.$router.push({
        path: `${path}`,
        query: { param: name },
      });
    },
    showSlideMenu() {
      this.drawer = !this.drawer;
    },
    handleClose(done) {
      done();
      // this.$confirm('确认关闭？')
      //   .then(_ => {
      //     done();
      //   })
      //   .catch(_ => {});
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped>
.pc_header {
  display: flex !important;
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
  background-color: var(--white00);
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
}

.pc_header:hover {
  background-color: white;
}

.bgbackground {
  background-color: var(--white00);
}

.phone_header {
  padding: 0 10px;
  display: none !important;
  justify-content: space-between !important;
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
  background: white;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);

  .phone_mune_div {
    // width: auto;
    height: 4rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.phone_slide_mune {
  margin: 8rem 0 0;
  background-color: transparent;
}

::v-deep .el-drawer {
  width: 70%;
  background-color: transparent !important;
}

.slide_mune {
  background: var(--bgBlue80);
  height: 100%;

  .el-menu-item {
    background-color: transparent !important;
  }

  .el-menu-item.is-active {
    background-color: var(--bgBlue) !important;
  }
}

.el-header {
  text-align: center;
  height: 8rem !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: light;
  padding: 0 22rem;
}

.el-menu-demo {
  height: 100%;
  background-color: transparent;
}

.el-menu.el-menu--horizontal {
  border-bottom: none;
}

.el-menu--horizontal>.el-menu-item {
  font-family: normal;
  font-size: 1.6rem;
  height: 8rem;
  line-height: 8rem;
  color: var(--textGary);
  text-decoration: none;
  border-bottom: transparent !important;
  letter-spacing: 2px;
}

.el-menu--horizontal>.el-menu-item:hover {
  border-bottom: transparent !important;
  background: var(--bgBlue) !important;
  color: white !important;
}

.el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: transparent !important;
  background: var(--mainColor) !important;
  color: white !important;
}

.el-aside {
  background-color: #D3DCE6;
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-main {
  background-color: white;
  padding: 0;
}

body > .el-container {
  margin-bottom: 40px;
}

.logo_div {
  width: fit-content;
  float: left;
  height: 4rem;

  img {
    height: 100%;
    vertical-align: middle;
    object-fit: contain;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
  }
}

.phone_div {
  height: 100%;
  width: fit-content;
  display: flex;
  align-items: center;

  img {
    height: 3.5rem;
    vertical-align: middle;
    object-fit: contain;
    margin: 0 2rem 0 10rem;
  }

  p {
    font-size: 3rem;
    font-family: bold;
    color: var(--phoneBlue);
  }
}

.el-footer {
  background: var(--mainColor);
  text-align: center;
  height: fit-content !important;
  padding: 0;

  .grid-content {
    // p:first-child {
    // cursor: auto;
    // font-size: 2rem;
    // font-family: normal;
    // margin: 0 0 1.6rem;
    // }
    p {
      // cursor: pointer;
      font-size: 1.3rem;
      text-align: left;
      color: white;
      margin: 1rem 0;
    }
  }
}

.ercode_father_div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ercode_div {
  margin-top: 5rem;

  img {
    width: 8.8rem;
    object-fit: contain;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
  }

  p {
    text-align: center !important;
    font-size: 1.5rem;
    font-family: regular;
  }
}

.foot_nav {
  width: 100%;
  margin: 5rem 0 5rem !important;
  padding: 0 24rem 0 22rem;
}

.foot_line {
  background: white;
  margin: 5rem 0 0;
}

.beian_div {
  background: white;
  display: flex;
  justify-content: center;
  padding: 3rem;

  a {
    font-family: exlight;
    font-size: 1.6rem;
    color: var(--black313333);
    margin: 1rem;
    text-decoration: none;
  }

  a:link {
    text-decoration: none;
  }

  a:active {
    text-decoration: none;
  }

  a:visited {
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }

  p {
    color: var(--black313333);
    margin: 1rem;
  }
}

.foot_title {
  cursor: pointer;
  font-size: 1.6rem !important;
  font-family: medium;
  margin: 0 0 1.6rem;
}

.foot_litter_line {
  margin: 0;
  background-color: white;
  height: 1px;
  width: 7rem;
}

@media screen and (max-width: 1400px) {
  .foot_nav {
    padding: 0 12rem 0 10rem;
  }
}

@media screen and (max-width: 1200px) {
  .foot_nav {
    padding: 0 2rem 0 1rem;
  }
}

@media screen and (max-width: 1000px) {
  .phone_foot_gone {
    display: none;
  }

  .pc_header {
    display: none !important;
  }

  .phone_header {
    display: flex !important;
    padding: 0 10px;
  }

  .beian_div {
    flex-direction: column;
  }

  .ercode_father_div {
    justify-content: space-around;
  }
}
</style>
