<template>
  <div>
    <img class="home_banner" :src="Detail.background" />
    <div class="about_title_div">
      <p class="mid_title">{{ Detail.type }}</p>
    </div>
    <div class="about_title_div2">
      <img :src="Detail.img" />
    </div>
    <div v-html="Detail.info" class="case_html"></div>
    <div>
      <!-- <video id="myVedio" autoplay="autoplay" controls="controls" width="800px">
        <source :src="Detail.video" />
      </video> -->

      <video
        id="video"
        v-show="Detail.video.length > 10"
        style="object-fit: contain; pointer-events: none; margin: 10rem 0"
        :src="Detail.video"
        autoplay="autoplay"
        preload="auto"
        controls="controls"
        loop="loop"
        width="80%"
        muted="muted"
        webkit-playsinline="true"
        playsinline="true"
        x5-video-player-type="h5"
        x5-video-player-fullscreen="true"
        x5-video-orientation="portraint"
      ></video>
      <!-- <video
          style="width:100%;height:100%;object-fit: contain"
          :src="Detail.video"
          controls="controls"
          controlslist="nodownload"
          width="100%"
        ></video> -->
    </div>
    <img class="bottom_img_div" :src="bottomImg" />
    <div class="news_more">
      <!-- <p @click="navto('/CaseDetail/id=' + prveNewsId)">
        上一篇：{{ prveNewsTitle }}
      </p>
      <p class="back_btn" @click="scrollNavto('/Case', 'part1')">返回</p>
      <p @click="navto('/CaseDetail/id=' + nextNewsId)">
        下一篇：{{ nextNewsTitle }}
      </p> -->
      <img
        @click="navto('/CaseDetail/id=' + nextNewsId)"
        src="../../static/img/右箭头.png"
      />
    </div>
  </div>
</template>

<script>
import countTo from "vue-count-to";
export default {
  data() {
    return {
      bottomImg:
        "http://xiecheng-imgs.oss-cn-hangzhou.aliyuncs.com/20241029/47efc1f8d99c46c9ad6f92b566c5dbf4.jpg",
      deviceType: "deviceone",
      prveNewsTitle: "",
      prveNewsId: "",
      nextNewsTitle: "",
      nextNewsId: "",
      Detail: {
        id: "",
        cover: "",
        background: "",
        img: "",
        info: "",
        name: "",
        pcDetailImg: "",
        phoneDetailImg: "",
        type: "",
        video: "",
      },
      NewsList: [
        {
          id: "",
          cover: "",
          background: "",
          img: "",
          info: "",
          name: "",
          pcDetailImg: "",
          phoneDetailImg: "",
          type: "",
          video: "",
        },
      ],
    };
  },
  watch: {
    // $route(to, from) {
    //   window.scrollTo(0, 0);
    //   console.log(to.path);
    //   console.log(from.path);
    //   this.getDetail();
    //   this.getCase();
    // },
    $route(to, from) {
      // window.scrollTo(0, 0);
      // console.log(to.path);
      console.log("CaseDetail==from path" + from.path);
      if (to.path.includes("/CaseDetail")) {
        console.log("CaseDetail==to path" + to.path);
        var param = this.$route.query.param;
        this.deviceType = param;
        console.log("param:" + param);
        this.init();
      }
    },
  },
  components: {
    countTo,
  },
  mounted() {
    // this.getDetail();
    // this.getCase();
    this.init();
    document.addEventListener(
      "touchstart",
      function () {
        var video = document.getElementById("video");
        video.play();
      },
      false
    );
  },
  methods: {
    init() {
      let type = this.$route.query.param;
      this.Detail.background = "";
      this.Detail.img = "";
      this.Detail.info = "";
      this.Detail.name = "";
      this.Detail.video = "";
      this.deviceType = type;
      setTimeout(() => {
        this.getDetail();
        this.getCase();
        window.scrollTo(0, 0);
      }, 1000);
    },
    navto(path) {
      if (this.nextNewsTitle != "无") {
        // this.$router.push(path);
        this.scrollNavto(path, this.deviceType);
      }
    },
    goback() {
      // this.$router.go(-1); //返回上一层
      this.$router.push("/Case");
    },
    scrollNavto(path, name) {
      this.$router.push({
        path: `${path}`,
        query: { param: name },
      });
    },
    getDetail() {
      let that = this;
      let id = window.location.href.split("id=")[1];
      this.$axios
        .get("app/" + that.deviceType + "/info/" + id)
        .then(function (response) {
          console.log(response);
          var status = response.data.code;
          if (status === 0) {
            if (that.deviceType === "deviceone") {
              that.Detail = response.data.deviceOne;
            } else if (that.deviceType === "devicetwo") {
              that.Detail = response.data.deviceTwo;
            } else {
              that.Detail = response.data.deviceThree;
            }
            console.log(that.Detail);
            // sessionStorage.setItem("input", "null");
          }
        })
        .catch((err) => console.log(err));
    },
    getCase() {
      let that = this;
      this.$axios
        .get("app/" + this.deviceType + "/list", {
          params: {
            page: 1,
            limit: 100,
          },
        })
        .then(function (response) {
          // console.log(response);
          var status = response.data.code;
          if (status === 0) {
            that.NewsList = response.data.page.list;
            console.log("case=" + that.NewsList.length);
            if (that.NewsList.length > 1) {
              for (let i = 0; i < that.NewsList.length; i++) {
                let item = that.NewsList[i];
                // console.log("id="+window.location.href.split("?")[0]);
                // console.log("id="+window.location.href.split("?")[0].slice(-2,-1));
                if (
                  item.id == window.location.href.split("?")[0].split("id=")[1]
                ) {
                  if (i != 0) {
                    that.prveNewsTitle = that.NewsList[i - 1].title;
                    that.prveNewsId = that.NewsList[i - 1].id;
                  } else {
                    that.prveNewsTitle = "无";
                  }
                  if (i < that.NewsList.length - 1) {
                    that.nextNewsTitle = that.NewsList[i + 1].title;
                    that.nextNewsId = that.NewsList[i + 1].id;
                  } else {
                    that.nextNewsTitle = "无";
                    that.nextNewsTitle = that.NewsList[0].title;
                    that.nextNewsId = that.NewsList[0].id;
                  }
                  // break;
                }
              }
            } else {
              that.nextNewsTitle = "无";
            }
            // console.log(that.NewsList);
            // sessionStorage.setItem("input", "null");
          }
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped>
video::-webkit-media-controls-enclosure {
  display: none;
}

.home_banner {
  width: 100%;
  display: block;
  object-fit: cover;
}

.about_title_div {
  margin: -10rem 0 10rem 0;
  position: absolute;
  width: auto;
  padding: 5rem 10rem;
  background-color: var(--topTitleBlue);

  .little_title {
    color: white;
    font-size: 4.8rem;
    font-family: light;
    margin: 0 5rem;
    text-align: left;
  }

  .mid_title {
    text-align: left;
    color: white;
    font-size: 4.8rem;
    font-family: medium;
    margin: 0 5rem;
  }
}

.about_title_div2 {
  margin: -43rem 0 0 0;
  position: absolute;
  right: 0;
  width: auto;

  // padding: 5rem;
  img {
    object-fit: contain;
    max-height: 60rem;
    margin-right: 30rem;
  }
}

.case_html {
  color: var(--textBlack);
  width: 100%;
  margin: 30rem auto 0;
  // text-align: left;
}

.bottom_img_div {
  width: 100%;
  display: block;
}

.news_more {
  padding: 1rem 0;
  width: 100%;
  // margin: 1rem auto;
  display: flex;
  color: var(--textBlack);
  font-size: 1.6rem;
  font-family: light;
  justify-content: center;
  align-items: center;

  img {
    width: 3rem;
    // background-size: contain;
    // background-repeat: no-repeat;
    height: 3rem;
    // background-image: url('../../static/img/右箭头.png');
  }

  // div:hover {
  // width: 5rem;
  // background-image: url('../../static/img/右箭头-2.png');
  // }
  p {
    cursor: pointer;
    max-width: 30vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .back_btn {
    color: var(--textBlue);
    border: 1px solid var(--textBlue);
    border-radius: 1rem;
    padding: 0.5rem 2rem;
  }
}

.news_more:hover {
  background: var(--mainColor);

  img {
    filter: grayscale(100) brightness(200);
  }
}

@media screen and (max-width: 2300px) {
  .about_title_div2 {
    img {
      margin-right: 10rem;
    }
  }
}

@media screen and (max-width: 1300px) {
  .about_title_div2 {
    margin: -30rem 0 10rem 0;
    padding: 0;

    img {
      max-height: 50rem;
      margin-right: 5rem;
    }
  }
}

@media screen and (max-width: 1100px) {
  .case_html {
    margin: 10rem auto 0;
  }

  .home_banner {
    height: 45vw;
    object-fit: cover;
  }

  .about_title_div {
    margin: -5rem 0 10rem 0;
    width: auto;
    padding: 2rem 2rem;

    p {
      padding: 0;
      font-size: 3rem;
    }

    .mid_title {
      margin: 0;
      font-size: 3rem;
    }
  }

  .about_title_div2 {
    margin: -8rem 0 10rem 0;
    padding: 0;

    img {
      max-height: 12rem;
      margin-right: 0;
    }
  }
}
</style>
