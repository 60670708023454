<template>
  <div>
    <img class="home_banner" :src="Detail.time" id="part1" />
    <div class="about_title_div">
      <p>案例展示——</p>
    </div>
    <div class="news_div">
      <div class="news_top_div">
        <div class="news_title_div">
          <p class="title">{{ Detail.name }}</p>
        </div>
        <!-- <p class="time">发布时间：{{ Detail.time }}</p> -->
        <!-- <div class="line"></div> -->
      </div>
      <!-- <div v-html="Detail.info" class="case_html"></div> -->
      <video
        class="case_html"
        id="video"
        style="width: 100%; height: 100%; object-fit: contain"
        :src="Detail.info"
        :poster="Detail.simple"
        controls="controls"
        controlslist="nodownload"
        width="100%"
      ></video>
      <!-- <div class="line"></div> -->
      <div class="news_more">
        <!-- <p @click="navto(prveNewsId)">上一篇：{{ prveNewsTitle }}</p> -->
        <p class="back_btn" @click="goback()">返回列表</p>
        <!-- <p @click="navto(nextNewsId)">下一篇：{{ nextNewsTitle }}</p> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      topImg:
        "https://xiecheng-imgs.oss-cn-hangzhou.aliyuncs.com/20220104/0f3fab7bbd224e3486b14c46288eea7e.jpg",
      prveNewsTitle: "",
      prveNewsId: "",
      nextNewsTitle: "",
      nextNewsId: "",
      Detail: {},
      NewsList: [
        {
          cover: "",
          time: "",
          name: "",
          info: "",
        },
      ],
    };
  },
  watch: {
    $route(to, from) {
      // console.log(to.path);
      window.scrollTo(0, 0);
      this.getDetail();
      this.getNews();
    },
  },
  components: {},
  mounted() {
    this.getDetail();
    this.getNews();
    this.hidevideocontrol();
  },
  methods: {
    hidevideocontrol() {
      $(document).ready(function () {
        $("#video").bind("contextmenu", function () {
          return false;
        });
      });
    },
    navto(path) {
      if (path) {
        this.$router.push("/NewsDetail/id=" + path);
      }
    },
    goback() {
      // this.$router.go(-1); //返回上一层
      this.$router.push("/News");
    },
    getDetail() {
      let that = this;
      let id = window.location.href.split("id=")[1];
      this.$axios
        .get("app/case/info/id=" + id)
        .then(function (response) {
          console.log(response);
          var status = response.data.code;
          if (status === 0) {
            that.Detail = response.data.case;
            console.log(that.Detail);
            // sessionStorage.setItem("input", "null");
          }
        })
        .catch((err) => console.log(err));
    },
    getNews() {
      let that = this;
      this.$axios
        .get("app/case/list", {
          params: {
            page: 1,
            limit: 100,
          },
        })
        .then(function (response) {
          // console.log(response);
          var status = response.data.code;
          if (status === 0) {
            that.NewsList = response.data.page.list;
            console.log("news=" + that.NewsList.length);
            for (let i = 0; i < that.NewsList.length; i++) {
              let item = that.NewsList[i];
              if (item.id == window.location.href.split("id=")[1]) {
                if (i != 0) {
                  that.prveNewsTitle = that.NewsList[i - 1].title;
                  that.prveNewsId = that.NewsList[i - 1].id;
                } else {
                  that.prveNewsTitle = "无";
                }
                if (i < that.NewsList.length - 1) {
                  that.nextNewsTitle = that.NewsList[i + 1].title;
                  that.nextNewsId = that.NewsList[i + 1].id;
                } else {
                  // that.nextNewsTitle = "无";
                  that.nextNewsTitle = that.NewsList[0].title;
                  that.nextNewsId = that.NewsList[0].id;
                }
                // break;
              }
            }
            // console.log(that.NewsList);
            // sessionStorage.setItem("input", "null");
          }
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped>
.home_banner {
  display: block;
  width: 100%;
  object-fit: cover;
}

.about_title_div {
  margin: -10rem 0 10rem 0;
  position: absolute;
  width: 40vw;
  background-color: var(--topTitleBlue);

  p {
    padding: 5rem 0 5rem 15rem;
    color: white;
    font-size: 6.4rem;
    font-family: bold;
    margin: 0;
    text-align: left;
    letter-spacing: 1rem;
  }
}

.news_div {
  width: 60%;
  margin: auto;

  .title {
    color: var(--mainColor);
    font-size: 4.8rem;
    font-family: bold;
    position: absolute;
    left: 0;
    right: 0;
  }

  .time {
    color: var(--textBlack);
    font-size: 1.6rem;
    margin: 5rem 0 0;
  }
}

.news_top_div {
  margin: 20rem 0 5rem;
}

.news_title_div {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .back_btn {
    cursor: pointer;
    position: absolute;
    display: inline-block;
    right: 0;
    color: var(--mainColor);
    border: 1px solid var(--mainColor);
    border-radius: 1rem;
    padding: 0.5rem 2rem;
  }
}

.case_html {
  margin:5rem 0;
  color: var(--textBlack);
  // text-align: left;
}

.line {
  margin: 5rem 0 0;
  height: 2px;
  // background: var(--gary7F7F7F);
  border-top: dotted var(--gary7F7F7F);
}

.news_more {
  padding: 1rem 0 10rem;
  margin: 1rem auto;
  display: flex;
  color: var(--textBlack);
  font-size: 1.6rem;
  font-family: light;
  justify-content: center;
  align-items: center;

  p {
    cursor: pointer;
    max-width: 30vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .back_btn {
    color: var(--mainColor);
    border: 1px solid var(--mainColor);
    border-radius: 1rem;
    padding: 0.5rem 2rem;
    font-size: 1.8rem;
    font-family: light;
  }
}

.back_btn:hover {
  background: var(--mainColor);
  color: white;
}

@media screen and (max-width: 1000px) {
  .home_banner {
    height: 45vw;
    object-fit: cover;
  }


  .about_title_div {
    width: auto;
    margin: -5rem 0 10rem 0;

    p {
      padding: 2rem 4rem;
      font-size: 4rem;
    }
  }

  .news_div {
    width: 90%;

    .time {
      margin: 7rem 0 0;
    }
  }

  .news_title_div {
    .back_btn {
      display: none;
    }
  }
}
</style>
