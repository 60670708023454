<template>
  <div>
    <HomeBanner></HomeBanner>
    <div class="about_div" id="part1">
      <p class="head_title">场景展示</p>
      <ShowLine></ShowLine>

      <div class="grid-content">
        <video
          id="video2"
          style="width:100%;height:100%;object-fit: contain"
          :src="List[0].info"
          :poster="List[1].info"
          controls="controls"
          controlslist="nodownload"
          width="100%"
        ></video>
        <div id="content-hover" class="content-hover" @click="hideBtn()">
          <img class="icon" src="../../static/img/video_icon.png" />
          <p class="title">模拟场景</p>
        </div>
      </div>
    </div>

    <div class="company_div">
      <div class="company_title_div">
        <!-- <div class="company_title_left_div">
          <p class="company_title">协成灭菌</p>
          <p class="company_title">深耕欧洲</p>
        </div>
        <div class="company_title_right_div">
          <p class="company_title_right">SINCE 2008</p>
        </div> -->
        <img src="../../static/img/img_title.png"/>
      </div>
      <div class="company_info_div">
        <div class="company_info_item_div">
          <div class="company_item_div">
            <!-- <p class="num">2005</p> -->
            <countTo
              class="num"
              ref="mynum"
              :startVal="0"
              :endVal="2005"
              :duration="3000"
              :separator="null"
              :autoplay="false"
            ></countTo>
            <p class="num_end">年</p>
          </div>
          <p class="num_title">公司成立</p>
        </div>
        <div class="company_info_item_div">
          <div class="company_item_div">
            <!-- <p class="num">66</p> -->
            <countTo
              class="num"
              ref="mynum1"
              :startVal="0"
              :endVal="66"
              :duration="3000"
              :separator="null"
              :autoplay="false"
            ></countTo>
            <p class="num_end">项</p>
          </div>
          <p class="num_title">获得专利</p>
        </div>
        <div class="company_info_item_div">
          <div class="company_item_div">
            <!-- <p class="num">86</p> -->
            <countTo
              class="num"
              ref="mynum2"
              :startVal="0"
              :endVal="86"
              :duration="3000"
              :separator="null"
              :autoplay="false"
            ></countTo>
            <p class="num_end">项</p>
          </div>
          <p class="num_title">获得认证</p>
        </div>
      </div>
    </div>

    <div class="honor_div" id="part3">
      <div class="honor_title">
        <p class="head_title_white">致力于为用户打造高效的消毒室空间</p>
        <p class="head_title_more" @click="scrollNavto('/Case', 'top')">
          了解更多
        </p>
      </div>
    </div>
    <div class="news_div" id="part4">
      <p class="head_title">应用领域</p>
      <ShowLine></ShowLine>
      <el-row>
        <el-col
          :xs="12"
          :sm="12"
          :md="12"
          :lg="12"
          :xl="12"
          class="home_list_img_div"
        >
          <img src="../../static/img/homelist1.png" />
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
          <div class="home_list_icon_div">
            <img class="icon_img" src="../../static/img/homelisticon1.png" />
            <p class="icon_title">口腔诊所</p>
            <p class="icon_content">用于口腔手术器械的快速灭菌</p>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
          <div class="home_list_icon_div">
            <img class="icon_img" src="../../static/img/homelisticon2.png" />
            <p class="icon_title">美容机构</p>
            <p class="icon_content">用于整形手术器械的快速灭菌</p>
          </div>
        </el-col>
        <el-col
          :xs="12"
          :sm="12"
          :md="12"
          :lg="12"
          :xl="12"
          class="home_list_img_div"
        >
          <img :src="homeImg2" />
        </el-col>
      </el-row>
      <el-row>
        <el-col
          :xs="12"
          :sm="12"
          :md="12"
          :lg="12"
          :xl="12"
          class="home_list_img_div"
        >
          <img :src="homeImg3" />
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
          <div class="home_list_icon_div">
            <img class="icon_img" src="../../static/img/homelisticon3.png" />
            <p class="icon_title">宠物医院</p>
            <p class="icon_content">用于宠物手术器械的快速灭菌</p>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
          <div class="home_list_icon_div">
            <img class="icon_img" src="../../static/img/homelisticon4.png" />
            <p class="icon_title">综合性医院</p>
            <p class="icon_content">用于临床科室手术器械的快速灭菌</p>
          </div>
        </el-col>
        <el-col
          :xs="12"
          :sm="12"
          :md="12"
          :lg="12"
          :xl="12"
          class="home_list_img_div"
        >
          <img :src="homeImg4" />
        </el-col>
      </el-row>
      <el-row>
        <el-col
          :xs="12"
          :sm="12"
          :md="12"
          :lg="12"
          :xl="12"
          class="home_list_img_div"
        >
          <img src="../../static/img/homelist5.png" />
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
          <div class="home_list_icon_div">
            <img class="icon_img" src="../../static/img/homelisticon5.png" />
            <p class="icon_title">实验室</p>
            <p class="icon_content">用于科学试验研究或试验用品的灭菌</p>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import HomeBanner from "../../src/components/HomeBanner.vue";
import HomeCaseBanner from "../../src/components/HomeCaseBanner.vue";
import ShowLine from "../components/ShowLine.vue";
import countTo from "vue-count-to";
export default {
  data() {
    return {
      path: "part1",
      homeImg2:"https://xiecheng-imgs.oss-cn-hangzhou.aliyuncs.com/20211209/2c13b8a72be942bb85f4c4721ed2421d.jpg",
      homeImg3:"https://xiecheng-imgs.oss-cn-hangzhou.aliyuncs.com/20211209/11e282d1196247b1b1ec98d9c934d242.jpg",
      homeImg4:"https://xiecheng-imgs.oss-cn-hangzhou.aliyuncs.com/20211209/80feca8f4caf4e0da4c13997612572c8.jpg",
      canScroll: true,
      List: [
        {
          info: "",
          name: "",
        },
        {
          info: "",
          name: "",
        },
      ],
      NewsList: [
        {
          title: "",
          img: "",
          simple: "",
          time: "",
        },
      ],
    };
  },
  components: {
    HomeBanner,
    HomeCaseBanner,
    ShowLine,
    countTo,
  },
  watch: {
    $route(to, from) {
      if (to.path === "/") {
        console.log(to.path);
        var param = this.$route.query.param;
        this.path = param;
        console.log("param:" + param);
        if (param === "top") {
          window.scrollTo(0, 0);
        } else {
          this.navtoPartPath(this.path);
        }
      }
    },
  },
  mounted() {
    this.getHomeinfo();
    this.hidevideocontrol();
    window.addEventListener("scroll", this.windowScrollListener);
  },
  methods: {
    hidevideocontrol() {
      $(document).ready(function () {
        $("#video2").bind("contextmenu", function () {
          return false;
        });
      });
    },
    scrollNavto(path, name) {
      this.$router.push({
        path: `${path}`,
        query: { param: name },
      });
    },
    navtoPartPath(path) {
      setTimeout(() => {
        document.getElementById(path).scrollIntoView();
      }, 500);
    },
    getHomeinfo() {
      let that = this;
      this.$axios
        .get("app/homeinfo/list")
        .then(function (response) {
          console.log(response);
          var status = response.data.code;
          if (status === 0) {
            that.List = response.data.page.list;
            console.log(that.List);
            // sessionStorage.setItem("input", "null");
          }
          // console.log("that.List");
        })
        .catch((err) => console.log(err));
    },
    navto(path) {
      this.$router.push(path);
    },
    windowScrollListener() {
      // 获取滚动条的高度
      var scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      var width = document.documentElement.clientWidth;
      console.log("scrollTop=" + scrollTop);
      var limitScroll = 600;
      if (width < 1000) {
        //手机端
        limitScroll = 10;
      } else {
        limitScroll = 1600;
      }
      if (scrollTop > limitScroll) {
        // 利用布尔值控制元素的显隐
        if (this.canScroll) {
          this.canScroll = false;
          this.handleScroll();
          console.log("startScroll=" + scrollTop);
        }
      }
      if (scrollTop < 100) {
        // this.canScroll = true;
        console.log("resetScroll=" + scrollTop);
      }
    },
    handleScroll() {
      // this.$refs.mynum.reset();
      this.$refs.mynum.start();
      this.$refs.mynum1.start();
      this.$refs.mynum2.start();
    },
    hideBtn() {
      console.log("hideBtn");
      var video = document.getElementById("video");
      document.getElementById("content-hover").style.display = "none";
      video.play();
    },
  },
  destroyed() {
    // 移除事件监听
    window.removeEventListener("scroll", this.windowScrollListener);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped>
.about_div {
  background: white;
  padding: 12rem 0 0;

  .title {
    margin: 3rem 0 0;
    font-size: 3.6rem;
    font-family: regular;
  }

  .subtitle {
    color: var(--textBlack);
    font-size: 1.6rem;
    margin: 0 0 5rem;
  }
}

.grid-content {
  position: relative;
}

.content-hover {
  background-image: url('../../static/img/视频播放按钮背景图.png');
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -14rem;
  margin-top: -13rem;
  height: 26rem;
  width: 28rem;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 3.6rem;
  font-family: regular;

  img {
    width: 10rem;
    height: 10rem;
  }
}

.head_title {
  font-size: 5rem;
  font-family: bold;
  color: var(--textBlue);
  margin: 0;
}

.head_title_white {
  font-size: 5rem;
  font-family: medium;
  color: white;
  margin: 0;
}

.head_title_more {
  cursor: pointer;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 5rem;
  width: auto;
  display: inline-block;
  padding: 1rem 3rem;
  font-size: 1.6rem;
  font-family: light;
}

.head_title_more:hover {
  background: white;
  color: var(--textBlue);
}

.case_div {
  background: white;
  padding: 12rem 0;
}

.company_div {
  padding: 12rem 0;
  width: 100%;
  background: var(--bgGary);

  .company_title_div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    img{
      height: 20rem;
      width:80%;
      object-fit: contain;
    }
    .company_title_left_div {
      .company_title {
        color: var(--textBlue);
        font-size: 5.5rem;
        font-family: bold;
        margin: 0;
      }
    }

    .company_title_right_div {
      border-top: 2px solid var(--textBlue);
      border-bottom: 2px solid var(--textBlue);
      margin: 0 1rem;

      .company_title_right {
        color: var(--textBlue);
        font-size: 9rem;
        font-family: Bebas;
        margin: 0;
        padding: 0 0 1rem;
        text-align: center;
      }
    }
  }

  .company_info_div {
    margin: 10rem 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    .company_info_item_div {
      .company_item_div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: baseline;
      }

      .num {
        font-size: 8rem;
        font-family: Bebas;
        color: var(--numTextColor);
        margin: 0;
      }

      .num_end {
        margin: 0 2rem;
        font-size: 3.5rem;
        font-family: medium;
        color: var(--numTextColor);
      }

      .num_title {
        font-size: 2rem;
        font-family: regular;
        color: var(--textBlue);
        margin: 0;
      }
    }
  }
}

.honor_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-items: center;

  .honor_title {
    background-image: url('../../static/img/home_sky_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding: 5rem 0 4rem;
  }

  .content {
    margin: 3rem 0 0;
    font-size: 1.6rem;
    color: white;
    text-align: justify;
    width: 40%;
    margin: auto;
  }

  img {
    // height: 20rem;
    width: 50%;
    margin-top: -10rem;
    margin-bottom: 10rem;
    object-fit: contain;
  }
}

.home_list_img_div {
  width: 50%;
  height: 56rem;
  object-fit: cover;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.home_list_icon_div {
  background: var(--bgGary);
  width: 100%;
  height: 56rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .icon_img {
    width: 9rem;
    height: 9rem;
  }

  .icon_title {
    font-family: bold;
    color: var(--textBlue);
    font-size: 4rem;
    margin: 2rem 0;
  }

  .icon_content {
    font-family: light;
    color: var(--textGary);
    font-size: 1.8rem;
    margin: 0;
  }
}

.home_list_icon_div:hover {
  background: var(--mainColor);

  img {
    filter: grayscale(100) brightness(200);
  }

  .icon_title {
    color: white;
  }

  .icon_content {
    color: white;
  }
}

.click_more_blue {
  cursor: pointer;
  width: 17rem;
  height: 4rem;
  background: var(--bgBlue);
  color: white;
  font-size: 1.6rem;
  padding: 1rem 3rem;
  border-radius: 1rem;
  line-height: 4rem;
  float: left;

  img {
    height: 1rem;
    margin: 0 0 0 2rem;
  }
}

.click_more_white {
  cursor: pointer;
  border: 1px solid var(--textBlue);
  width: fit-content;
  height: 3rem;
  background: white;
  color: var(--textBlue);
  font-size: 1.6rem;
  padding: 0.5rem 3rem;
  border-radius: 1rem;
  line-height: 3rem;
  float: left;

  .icon_img {
    display: inline-block;
    background-image: url('../../static/img/click_more_icon_blue.png');
    background-repeat: no-repeat;
    background-size: contain;
    height: 1rem;
    width: 4rem;
    margin: 0 0 0 2rem;
  }
}

.click_more_white:hover {
  background: var(--textBlue);
  color: white;

  .icon_img {
    background-image: url('../../static/img/click_more_icon.png');
  }
}

.news_div {
  background: white;
  padding: 3rem 0;
  width: 100%;
  margin: 6.5rem auto;
}

.news_item {
  width: 46rem;
  margin: 10rem 0;

  .news_top {
    width: 100%;
    height: 34rem;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .time_div {
    background: var(--bgBlue80);
    position: absolute;
    top: 0;
    left: 5rem;
    width: 13rem;
    height: 15rem;
    text-align: left;
    margin: -2rem 0 0;
    padding: 3rem;

    .line {
      color: white;
      background: white;
      height: 1px;
      width: 2rem;
      margin: 0;
    }

    .day {
      margin: 0;
      font-size: 2.5rem;
      color: white;
      font-family: normal;
    }

    .year {
      font-family: normal;
      font-size: 1.6rem;
      color: white;
    }
  }

  .title {
    color: var(--textBlack);
    font-size: 2rem;
    font-family: regular;
    text-align: left;
    margin: 2rem 0 0;
  }

  .hr_line {
    color: var(--bgBlue);
    background: var(--bgBlue);
    height: 1px;
    margin: 1rem 0 2rem;
  }

  .content {
    color: var(--textBlack);
    text-align: left;
    font-size: 1.6rem;
    font-family: exlight;
    margin: 0 0 5rem;
  }
}

@media screen and (max-width: 1000px) {
  .about_div {
    .img_div {
      .grid-content {
        width: 100%;
        height: 50rem;
      }

      .content-hover {
        .icon {
          width: 8rem;
          height: 8rem;
        }
      }
    }

    .title {
      font-size: 2rem;
    }
  }

  .content-hover {
    height: 16rem;
    width: 18rem;
    margin-left: -9rem;
    margin-top: -8rem;

    img {
      width: 5rem;
      height: 5rem;
    }
  }

  .head_title_white {
    font-size: 3rem;
  }

  .home_list_img_div {
    height: 50vw;
  }

  .home_list_icon_div {
    height: 50vw;

    .icon_img {
      width: 7rem;
      height: 7rem;
      object-fit: contain;
    }

    .icon_title {
      font-size: 3rem;
    }

    .icon_content {
      font-size: 1.4rem;
    }
  }

  .honor_div .content {
    width: 90%;
  }

  .honor_div {
    .honor_title {
      padding: 2rem 0;
    }

    img {
      width: 90%;
      margin-top: -5rem;
    }
  }
  .company_div {

  .company_title_div {

    .company_title_left_div {
      .company_title {
        font-size: 4.5rem;
      }
    }

    .company_title_right_div {

      .company_title_right {
        font-size: 8rem;
      }
    }
  }
}
}
@media screen and (min-width: 1950px) {
.home_list_img_div {
    height: 70rem;
  }

  .home_list_icon_div {
    height: 70rem;
  }
}
</style>
