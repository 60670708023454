<template>
  <div>
    <div class="home_banner">
      <img :src="topImg" />
      <div class="about_title_div">
        <p>公司简介——</p>
      </div>
    </div>
    <div class="about_div" id="part1">
      <div class="about_mid_div">
        <div class="about_left_div">
          <!-- <div class="about_num_div">
            <countTo
              class="num"
              ref="mynum"
              :startVal="0"
              :endVal="2005"
              :duration="3000"
              :separator="null"
              :autoplay="false"
            ></countTo>
            <p class="num_end">年</p>
          </div>
          <p class="num_title">只 做 超 高 性 价 比 灭 菌 器</p> -->
          <div class="company_title_div">
            <div class="company_title_left_div">
              <p class="company_title">协成灭菌</p>
              <p class="company_title">深耕欧洲</p>
            </div>
            <div class="company_title_right_div">
              <p class="company_title_right">SINCE 2005</p>
            </div>
          </div>
        </div>
        <div class="about_right_div">
          <img class="left_img" src="../../static/img/左上角框.png" />
          <div>
            <p class="about_p">
              {{ companyList[1].info.replace(/<[^>]*>/g, '') }}
            </p>
            <p class="about_p2">
              {{ companyList[2].info.replace(/<[^>]*>/g, '') }}
            </p>
          </div>
          <img class="right_img" src="../../static/img/右下角框.png" />
        </div>
      </div>
      <!-- <div class="about_left_bottom_p">
        <p>· 协成医疗愿意与您携手，为中国口腔事业的繁荣贡献一份力量！</p>
      </div> -->
    </div>
    <div class="about_div2">
      <p class="head_title">{{ companyList[0].name }}</p>
      <ShowLine></ShowLine>
      <div v-html="companyList[0].info" class="case_html"></div>
    </div>
    <div class="about_div3">
      <p class="head_title">{{ renzhengList[0].name }}</p>
      <ShowLine></ShowLine>
      <div v-html="renzhengList[0].info" class="case_html"></div>
    </div>
    <div class="about_div4">
      <el-row>
        <el-col :md="24" :lg="12" v-for="(item, index) in filelist" :key="index">
          <div class="item_div" @click="goFile(item.address)">
            <img src="../../static/img/icon_pdf.png" />
            <p>{{ item.name }}</p>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import ShowLine from "../components/ShowLine.vue";
import countTo from "vue-count-to";
export default {
  data() {
    return {
      path: "/About",
      topImg:
        "https://xiecheng-imgs.oss-cn-hangzhou.aliyuncs.com/20211209/729aedccb8a247c3a5d5e13aee4c4c8c.jpg",
      canScroll: true,
      filelist: [],
      companyList: [
        {
          name: "",
          info: "",
        },
      ],
      renzhengList: [
        {
          name: "",
          info: "",
        },
      ],
    };
  },
  components: { ShowLine, countTo },
  watch: {
    $route(to, from) {
      if (to.path === "/About") {
        console.log(to.path);
        var param = this.$route.query.param;
        this.path = param;
        console.log("param:" + param);
        if (param === "top") {
          window.scrollTo(0, 0);
        } else {
          this.navtoPartPath(this.path);
        }
      }
    },
  },
  mounted() {
    this.getCompanyinfo(), this.getRenzhenginfo();
    this.getFilelist();
    var width = document.documentElement.clientWidth;
    if (this.canScroll) {
      this.canScroll = false;
      this.handleScroll();
    }
    window.addEventListener("scroll", this.windowScrollListener);
  },
  methods: {
    navtoPartPath(path) {
      setTimeout(() => {
        document.getElementById(path).scrollIntoView();
      }, 500);
    },
    getFilelist() {
      let that = this;
      this.$axios
        .get("app/list")
        .then(function (response) {
          console.log(response);
          var status = response.data.code;
          if (status === 0) {
            that.filelist = response.data.page;
            console.log(that.filelist);
            // sessionStorage.setItem("input", "null");
          }
          // console.log("that.List");
        })
        .catch((err) => console.log(err));
    },
    getCompanyinfo() {
      let that = this;
      this.$axios
        .get("app/company/list")
        .then(function (response) {
          console.log(response);
          var status = response.data.code;
          if (status === 0) {
            that.companyList = response.data.page.list;
            console.log(that.List);
            // sessionStorage.setItem("input", "null");
          }
          // console.log("that.List");
        })
        .catch((err) => console.log(err));
    },
    getRenzhenginfo() {
      let that = this;
      this.$axios
        .get("app/renzheng/list")
        .then(function (response) {
          console.log(response);
          var status = response.data.code;
          if (status === 0) {
            that.renzhengList = response.data.page.list;
            console.log(that.List);
            // sessionStorage.setItem("input", "null");
          }
          // console.log("that.List");
        })
        .catch((err) => console.log(err));
    },
    windowScrollListener() {
      // 获取滚动条的高度
      var scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      var width = document.documentElement.clientWidth;
      console.log("scrollTop=" + scrollTop);
      var limitScroll = 250;
      if (width < 1000) {
        //手机端
        limitScroll = 10;
      } else {
        limitScroll = 250;
      }
      if (scrollTop > limitScroll) {
        // 利用布尔值控制元素的显隐
        if (this.canScroll) {
          this.canScroll = false;
          this.handleScroll();
          console.log("startScroll=" + scrollTop);
        }
      }
      if (scrollTop < 100) {
        // this.canScroll = true;
        console.log("resetScroll=" + scrollTop);
      }
    },
    handleScroll() {
      // this.$refs.mynum.reset();
      this.$refs.mynum.start();
    },
    goFile(address) {
      window.open(address, "_blank");
    },
  },
  destroyed() {
    // 移除事件监听
    window.removeEventListener("scroll", this.windowScrollListener);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped>
.home_banner {
  width: 100%;
  display: block;
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }
}

.company_title_div {
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .company_title_left_div {
    .company_title {
      color: var(--textBlue);
      font-size: 5.5rem;
      font-family: bold;
      margin: 0;
      line-height: 1.2;
    }
  }

  .company_title_right_div {
    border-top: 2px solid var(--textBlue);
    border-bottom: 2px solid var(--textBlue);
    margin: 0 1rem;

    .company_title_right {
      color: var(--textBlue);
      font-size: 9rem;
      font-family: Bebas;
      margin: 0;
      padding: 0 0 1rem;
      text-align: center;
      line-height: 1.2;
    }
  }
}

.head_title {
  font-size: 5rem;
  font-family: bold;
  color: var(--textBlue);
  margin: 0 0 3rem;
}

.company_div {
  width: 100%;
  height: 80rem;
  background-image: url('../../static/img/关于公司建筑.png');
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}

.company_simple_div {
  padding: 6rem 20rem;
  position: absolute;
  right: 0;
  bottom: 10%;
  height: 30rem;
  width: 100rem;
  background-image: url('../../static/img/关于文字背景图.png');
  background-repeat: no-repeat;
  background-size: contain;
  color: white !important;
  text-align: left !important;

  .title {
    font-size: 3rem !important;
    color: white !important;
    text-align: left !important;
  }

  .line {
    margin: 3rem 0;
    background: white;
    height: 1px;
  }

  .content {
    font-size: 1.7rem !important;
    color: white !important;
    text-align: left !important;
  }
}

.about_title_div {
  margin: -10rem 0 10rem 0;
  position: absolute;
  width: 40vw;
  background-color: var(--topTitleBlue);

  p {
    padding: 5rem 0 5rem 15rem;
    color: white;
    font-size: 6.4rem;
    font-family: bold;
    margin: 0;
    text-align: left;
    letter-spacing: 1rem;
  }
}

.about_div {
  background-image: url('https://xiecheng-imgs.oss-cn-hangzhou.aliyuncs.com/20211209/643096656bb24d078eaadd64858c861c.jpg');
  background-repeat: no-repeat;
  background-size: cover;

  // position: relative;
  .about_mid_div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 20rem 15rem 15rem;

    .about_left_div {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .about_num_div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: baseline;
      }
    }

    .about_right_div {
      width: 50%;
      position: relative;

      .left_img {
        position: absolute;
        left: 0;
      }

      .right_img {
        position: absolute;
        bottom: 0;
        right: 0;
      }

      .about_p {
        padding: 1rem 0;
        text-align: justify;
        color: var(--gary7F7F7F);
        font-family: medium;
        font-size: 2.2rem;
        line-height: 1.6;
        letter-spacing: 0.3rem;
        margin-bottom: 0;
      }

      .about_p2 {
        margin-top: 0;
        padding: 1rem 0;
        text-align: justify;
        color: var(--gary7F7F7F);
        font-family: medium;
        font-size: 2.2rem;
        line-height: 1.6;
        letter-spacing: 0.3rem;
      }

      div {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 6rem;
      }
    }
  }

  .about_left_bottom_p {
    width: 50%;
    padding: 5rem 0 15rem 15rem;

    p {
      text-align: left;
      color: var(--gary7F7F7F);
      font-family: medium;
      font-size: 2.2rem;
      letter-spacing: 0.3rem;
    }
  }

  .num {
    font-size: 23rem;
    font-family: Bebas;
    color: var(--garyCCCCCC);
    margin: 0;
  }

  .num_end {
    margin: 0 2rem;
    font-size: 4.5rem;
    font-family: bold;
    color: var(--garyCCCCCC);
  }

  .num_title {
    font-size: 4.5rem;
    font-family: bold;
    color: var(--textBlue);
    margin: 0;
  }
}

.about_div2 {
  background: white;
  padding: 12rem 0 0;
}

.about_div3 {
  background: var(--bgGary);
  padding: 12rem 0 0;
}

.about_div4 {
  width: 80%;
  margin: auto;
  background: white;
  padding: 0 0 16px 0 ;

  .item_div {
    margin:  1rem 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 8rem;
    border: dashed 2px #dcdcdc;
    border-radius: 6px;

    img {
      height: 6rem;
      width: auto;
    }

    p {
      margin: 0 1rem;
      font-size: 3rem;
      font-family: bold;
      color: var(--textBlue);
    }
  }
  .item_div:hover{
    background-color: var(--textBlue);
    border: dashed 2px var(--textBlue);
    p {
      color: #FFF;
    }
  }
}

.case_html {
  color: var(--textBlack);
  // text-align: left;
}

@media screen and (max-width: 2300px) {
  .company_title_div {
    .company_title_left_div {
      .company_title {
        font-size: 5rem;
      }
    }

    .company_title_right_div {
      .company_title_right {
        font-size: 8rem;
        padding: 0 0 1rem;
      }
    }
  }
}

@media screen and (max-width: 1700px) {
  .company_title_div {
    margin-top: 9rem;
  }
}

@media screen and (max-width: 1400px) {
  .home_banner {
    height: 45vw;
    object-fit: cover;
  }

  .company_simple_div {
    height: auto;
    padding: 0;
    // margin:1rem;
    width: 100%;
    background-size: cover;
  }

  .about_title_div {
    width: auto;

    p {
      padding: 5rem;
      font-size: 4rem;
    }
  }

  .about_div {
    .about_mid_div {
      padding: 2rem;

      .about_left_div {
        width: 100%;
      }

      .about_right_div {
        width: 100%;
        margin: 5rem 0;
      }
    }

    .about_left_bottom_p {
      width: 100%;
      padding: 5rem 0;
      margin: 5rem 0;
    }
  }
}

@media screen and (max-width: 1200px) {
  .about_div {
    .about_mid_div {
      flex-direction: column;
      padding: 2rem;

      .about_left_div {
        width: 100%;
        align-items: center;
      }

      .about_right_div {
        width: 100%;
        margin: 5rem 0;
      }
    }

    .about_left_bottom_p {
      width: 100%;
      padding: 5rem 0;
      margin: 5rem 0;
    }
  }

  .company_title_div {
    .company_title_left_div {
      .company_title {
        font-size: 4rem;
      }
    }

    .company_title_right_div {
      .company_title_right {
        font-size: 7rem;
        padding: 0 0 1rem;
      }
    }
  }

  .about_title_div {
    margin: -5rem 0 10rem 0;

    p {
      padding: 2rem 4rem;
      font-size: 4rem;
    }
  }
}
</style>
