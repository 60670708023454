<template>
  <div>
    <div class="home_banner">
      <!-- <img class="home_banner" src="../../static/img/联系我们顶部图.png" /> -->
      <div id="baidumap" class="map_div"></div>
      <div class="div_top_title">
        <div class="div_top_sub">
          <img src="../../static/img/logo2.png" />
          <div>
            <p class="p_title_sub">电 话·86 - 574 - 88425999</p>
            <p class="p_title_sub">邮 箱·enquiry@yeson-medicine.com</p>
            <p class="p_title_sub">地 址·宁波市海曙区横街镇环镇大道1号</p>
          </div>
        </div>
      </div>
    </div>

    <div class="heading">联系我们</div>
    <ShowLine></ShowLine>
    <div class="container father_div">
      <div class="father_sub_div">
        <div class="right_div">
          <div class="input_type_div">
            <div class="input-group">
              <input
                v-model="dataForm.company"
                type="text"
                class="form-control"
                placeholder="公司名称（选填）"
                aria-label="..."
              />
            </div>
            <div class="input-group">
              <input
                v-model="dataForm.name"
                placeholder="姓名（必填）"
                type="text"
                class="form-control"
                aria-label="..."
              />
            </div>
          </div>
          <div class="input_type_div">
            <div class="input-group">
              <input
                v-model="dataForm.email"
                type="text"
                class="form-control"
                placeholder="电子邮箱（选填）"
                aria-label="..."
              />
            </div>
            <div class="input-group">
              <input
                v-model="dataForm.tel"
                placeholder="联系电话（必填）"
                type="text"
                class="form-control"
                aria-label="..."
              />
            </div>
          </div>
          <div class="input_type_div">
            <div class="input-group">
              <input
                v-model="dataForm.country"
                type="text"
                class="form-control"
                placeholder="国家/地区（选填）"
                aria-label="..."
              />
            </div>
            <div class="input-group">
              <input
                v-model="dataForm.industry"
                placeholder="行业（选填）"
                type="text"
                class="form-control"
                aria-label="..."
              />
            </div>
          </div>
          <div class="input_type_div"></div>

          <!-- /.row -->
          <div class="input-group msg_input">
            <textarea
              v-model="dataForm.message"
              placeholder="留言内容（选填）"
            ></textarea>
          </div>
          <div class="btn-group">
            <div class="btn-group-sub">
              <div @click="onSubmit()" class="btn-default">提交</div>
              <div @click="clearForm()" type="button" class="btn-default">
                取消
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>
<script>
import ShowLine from "../components/ShowLine.vue";
export default {
  components: {
    ShowLine,
  },
  data() {
    return {
      dataForm: {
        id: 0,
        name: "",
        company: "",
        country: "",
        email: "",
        industry: "",
        tel: "",
        message: "",
      },
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop);
    this.initMap();
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
  methods: {
    initMap() {
      var map = new BMap.Map("baidumap", {
        enableBizAuthLogo: false,
      });
      var point = new BMap.Point(121.409682, 29.857152);
      map.enableScrollWheelZoom(true);
      map.centerAndZoom(point, 13);
      map.addEventListener("zoomend", function () {
        // alert("地图缩放至：" + this.getZoom() + "级");
        map.panTo(new BMap.Point(121.409682, 29.857152));
      });
      window.setTimeout(function () {
        map.panTo(new BMap.Point(121.409682, 29.857152));
      }, 2000);
      var marker = new BMap.Marker(point); // 创建标注
      map.addOverlay(marker); // 将标注添加到地图中
    },
    onSubmit() {
      if (
        // this.dataForm.company != "" &&
        // this.dataForm.country != "" &&
        // this.dataForm.industry != "" &&
        // this.dataForm.email != "" &&
        // this.dataForm.message != "" &&
        this.dataForm.name != "" &&
        this.dataForm.tel != ""
      ) {
        this.dataFormSubmit();
        console.log("submit!");
        this.clearForm();
      } else {
        this.$message.warning("请输入姓名和联系电话")
      }
    },
    clearForm() {
      this.dataForm.company = "";
      this.dataForm.name = "";
      this.dataForm.tel = "";
      this.dataForm.email = "";
      this.dataForm.industry = "";
      this.dataForm.country = "";
      this.dataForm.message = "";
    },
    // 表单提交
    dataFormSubmit() {
      this.$axios
        .post(
          "app/visitormessage/save",
          JSON.stringify({
            company: this.dataForm.company,
            industry: this.dataForm.industry,
            email: this.dataForm.email,
            country: this.dataForm.country,
            name: this.dataForm.name,
            tel: this.dataForm.tel,
            message: this.dataForm.message,
          }),
          {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
            },
          }
        )
        .then(
          function (response) {
            var status = response.data.code;
            if (status === 0) {
              this.$message({
                message: "提交成功",
                type: "success",
              });
            }
          }.bind(this)
        );
    },
  },
};
</script>
<style lang="stylus" scoped>
input::-webkit-input-placeholder {
  color: var(--textBlue);
  font-size: 14px;
}

input, input:focus {
  border: none !important;
  box-shadow: none !important;
}

input {
  color: var(--textBlue);
  font-size: 14px;
  box-shadow: none;
}

textarea {
  width: 100%;
  height: 100%;
  border: none;
  color: #3c3c3c;
  font-size: 14px;
  padding: 1rem;
  outline: none;
}

textarea, textarea:focus {
  border: none !important;
  box-shadow: none !important;
}

textarea::-webkit-input-placeholder {
  color: var(--textBlue);
  font-size: 14px;
}

.home_banner {
  position: relative;
  width: 100%;
  height: 85rem;
  object-fit: cover;
  margin-left: auto;
  margin-right: auto;
  --swiper-pagination-color: var(--textBlue);

  .map_div {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.heading {
  padding: 15rem 0 0 0;
  font-size: 4.8rem;
  color: var(--textBlue);
  text-align: center;
  font-family: bold;
  margin-bottom: 3rem;
}

.father_div {
  width: 100%;
  background-color: var(--bgGary);
  margin: 4rem 0 0 0;
}

.father_sub_div {
  padding: 2rem 16rem;
}

.left_div {
  height: 50vw;
}

.right_div {
  height: auto;
  min-height: 35vw;
  width: 100%;
  overflow: hidden;
}

.title {
  font-size: 25px;
  margin: 30px 0;
  color: #F3C23E;
}

.contain {
  font-size: 1.5rem;
  line-height: 3rem;
  text-align: left;
}

.contain_tv1 {
  font-size: 1.5rem;
  font-family: bold;
  margin: 0;
  text-align: left;
}

.contain_tv2 {
  font-family: bold;
  font-size: 2.5rem;
  margin: 0;
  text-align: left;
}

.contain_img {
  width: 6rem;
  height: 6rem;
}

.demo-form-inline {
  margin-top: 30px;
}

.inputbox {
  display: flex;

  div {
    font-size: 14px;
    margin-right: 20px;
  }
}

.btnbox {
  text-align: center;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.flex-container > div {
  padding: 20px;
}

.litter_line {
  margin: 2rem 0;
  background-color: black;
  height: 1px;
  width: 50%;
}

input {
  color: #3c3c3c !important;
}

.input_type_div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.input-group {
  width: 48%;
  height: 5rem;
  margin: 1rem 0;
}

.input-group .form-control {
  height: 100%;
  width: 100%;
  border-radius: 0;
  border: none;
  padding: 0 0 0 1rem;
  outline:none;
}

.input-group-addon {
  background-color: #fff;
  border: 0;
  border-radius: 0;
}

.msg_input {
  width: 100% !important;
  height: 30rem;
}

.my_btn_group {
  margin: auto;
  margin-top: 1rem;
  width: 50%;
}

.btn-group {
  margin: auto;
  margin-top: 4rem;
  width: 100%;
  text-align: center;

  .btn-group-sub {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: var(--mainColor);
    font-size: 2rem;
    font-family: regular;
  }
}

.btn-default {
  cursor: pointer;
  padding: 0.5rem 3rem;
  border: 1px solid var(--mainColor);
  margin: 2rem 2rem;
  text-align: center;
  border-radius: 10rem;
}

.btn-default:hover {
  border-color: var(--textBlue);
  background-color: var(--textBlue);
  color: #fff;
}

.div_top_title {
  padding: 4rem 5rem;
  background-color: var(--mainColor);
  width: auto;
  position: absolute;
  bottom: -7%;
  right: 0;
  color: #fff;
  font-size: 3.8rem;
  font-family: regular;
  text-align: left;

  .div_top_sub {
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      filter: grayscale(100) brightness(200);
      object-fit: contain;
      margin: 0 3rem 0 0;
      height: 8rem;
      // image-rendering: -moz-crisp-edges;
      // image-rendering: -o-crisp-edges;
      // image-rendering: -webkit-optimize-contrast;
      // image-rendering: crisp-edges;
      // -ms-interpolation-mode: nearest-neighbor;
    }
  }

  .p_title {
    padding: 10px;
    margin: 0 1rem;
  }

  .p_title_sub_tv {
    font-size: 2rem;
    font-family: regular;
    padding: 10px 0 10px 10px;
    margin: 0 0 0 1rem;
  }

  .p_title_sub_tv2 {
    font-size: 2rem;
    font-family: regular;
    padding: 10px 0 10px 0;
    margin: 0 1rem 0 0;
  }

  .p_title_sub {
    font-size: 2rem;
    font-family: regular;
    // padding: 10px;
    margin: 0 1rem;
  }

  .litter_line_top {
    margin: auto;
    background-color: white;
    height: 1px;
    width: 20%;
  }
}

@media (max-width: 1450px) {
  .father_div {
    height: 100vh;
  }
}

@media (max-width: 1100px) {
  // .div_top_title {
  // display: none;
  // }
}

@media (max-width: 800px) {
  .home_banner {
    width: 100%;
    height: 45vw;
  }

  .heading {
    margin-top: 5rem;
  }

  .div_top_title {
    position: unset;
    padding:2rem 5rem;

    .div_top_sub {
      img {
        width: 10rem;
      }
    }
  }

  .father_div {
    height: 100vh;
  }

  .father_sub_div {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .left_div {
    height: 60vw;
  }

  .right_div {
    height: auto;
    margin-rignt: 1rem !important;
  }
}
</style>
