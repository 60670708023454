// 1. 引入
import axios from "axios";

// 2. 配置信息
let config = {
    // 每次请求的协议、IP地址。  设置该配置后，每次请求路径都可以使用相对路径，例如"/admin/login"
    // baseURL: "http://120.55.73.87/demo",
    baseURL: "https://yesonmed.cn/demo",
    // baseURL: "http://localhost:8082/demo",
    // 请求超时时间
    timeout: 60000,
    // 每次请求携带cookie
    withCredentials: true
}

// 3. 创建实例
const instance = axios.create(config)

// 4. 导出
export default instance
