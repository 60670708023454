import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
// import {Container,Drawer,Menu,MenuItem,MenuItemGroup,Header,Aside,Main,Footer,Row,Col,Message} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);
// Vue.use(Message);
// Vue.use(Container);
// Vue.use(Drawer);
// Vue.use(Menu);
// Vue.use(MenuItem);
// Vue.use(MenuItemGroup);
// Vue.use(Header);
// Vue.use(Aside);
// Vue.use(Main);
// Vue.use(Footer);
// Vue.use(Row);
// Vue.use(Col);

Vue.config.productionTip = false
// 1. 引入实例
import axios from './axios/axios_config.js'
// 因为我在 axios 文件夹下只创建了一个 index.js 文件，那么 webpack 会默认把该文件打包，所以这里的路径可以不用加上 '/index.js' 
// 如果你创建的文件名不是 index，那么一定要加上文件名
// 如果你的 axios 配置文件跟我创建的路径不一样，那么按照你的路径引入就可以了

// 2. 与Vue实例关联
Vue.prototype.$axios = axios
new Vue({
  el:"#app",
  router,
  render: h => h(App)
}).$mount('#app')
