<template>
  <div>
    <div class="litter_line">
      <!-- <div class="litter_circle"></div> -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  computed: {},
  mounted() {},
  destroyed() {},
  methods: {},
};
</script>
<style lang="stylus" scoped>
.litter_line {
  position: relative;
  margin: 3rem auto;
  background-color: var(--garyAEAEAE);
  height: 2px;
  width: 10rem;
}

.litter_line>div {
  position: absolute;
}

.litter_circle {
  margin: -3.5px 45% 0;
  top: 0;
  background-color: var(--textBlue);
  height: 10px;
  width: 10px;
  border-radius: 50%;
}
</style>