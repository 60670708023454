<template>
  <div>
    <img
      class="home_banner"
      :src="topImg"
      id="part1"
    />

    <div class="honor_div">
      <p class="head_title">服务承诺</p>
      <ShowLine></ShowLine>

      <div class="honor_info_div">
        <div class="honor_left_div">
          <div class="left_top_item_div">
            <img src="../../static/img/联系我们勾.png" />
            <div>
              <p class="left_num">24</p>
              <p class="left_p">小时响应</p>
            </div>
          </div>
          <div class="left_top_item_div">
            <img src="../../static/img/联系我们勾.png" />
            <div>
              <p class="left_num">72</p>
              <p class="left_p">小时解决问题</p>
            </div>
          </div>
          <div class="left_bottom_item_div">
            <img :src="HomeList[2].info" />
            <p>售后客服</p>
          </div>
        </div>
        <div class="honor_right_div">
          <img class="left_img" src="../../static/img/左上角框.png" />
          <img class="center_img" src="../../static/img/联系我们图片.png" />
          <img class="right_img" src="../../static/img/右下角框.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShowLine from "../components/ShowLine.vue";
export default {
  data() {
    return {
      path: "part1",
      topImg:"https://xiecheng-imgs.oss-cn-hangzhou.aliyuncs.com/20211209/2797fe56f69f4dbb97792dd2b502b2e6.jpg",
      List: [
        {
          title: "",
          img: "",
        },
        {
          title: "",
          img: "",
        },
      ],
      HomeList:[]
    };
  },
  watch: {
    $route(to, from) {
      if (to.path === "/Brand") {
        console.log(to.path);
        var param = this.$route.query.param;
        window.scrollTo(0, 0);
        // this.path = param;
        // console.log("param:" + param);
        // this.navtoPartPath(this.path);
      }
    },
  },
  components: { ShowLine },
  mounted() {
    this.getHomeinfo();
    window.scrollTo(0, 0);
  },
  methods: {
    navtoPartPath(path) {
      setTimeout(() => {
        document.getElementById(path).scrollIntoView();
      }, 500);
    },
    getHonor() {
      let that = this;
      this.$axios
        .get("app/honor/list")
        .then(function (response) {
          console.log(response);
          var status = response.data.code;
          if (status === 0) {
            that.List = response.data.page.list;
            console.log(that.List);
            // sessionStorage.setItem("input", "null");
          }
          // console.log("that.List");
        })
        .catch((err) => console.log(err));
    },
    getHomeinfo() {
      let that = this;
      this.$axios
        .get("app/homeinfo/list")
        .then(function (response) {
          console.log(response);
          var status = response.data.code;
          if (status === 0) {
            that.HomeList = response.data.page.list;
            console.log(that.HomeList);
            // sessionStorage.setItem("input", "null");
          }
          // console.log("that.List");
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped>
.home_banner {
  display: block;
  width: 100%;
}

.head_title {
  font-size: 4.8rem;
  font-family: bold;
  color: var(--textBlue);
  margin: 0;
}

.honor_div {
  margin-top: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-items: center;
}

.honor_info_div {
  background: var(--bgGary);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;

  .honor_left_div {
    width: 50%;
    margin: 10rem 0 10rem 22rem;
    height: 48rem;

    .left_top_item_div {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: baseline;

      img {
        width: 6rem;
        height: 6rem;
        object-fit: cover;
      }

      div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;

        .left_num {
          color: var(--textBlue);
          font-size: 6.6rem;
          font-family: Bebas;
          margin: 0 3rem;
        }

        .left_p {
          color: var(--textGary);
          font-size: 4.4rem;
          font-family: regular;
          margin: 0;
        }
      }
    }

    .left_bottom_item_div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 10rem;

      img {
        width: 14rem;
        height: 14rem;
      }

      p {
        color: var(--textGary);
        font-size: 2.3rem;
        font-family: medium;
        width: 14rem;
        margin: 1rem 0;
      }
    }
  }

  .honor_right_div {
    width: 50%;
    position: relative;
    margin: 10rem 10rem 10rem 0;

    .center_img {
      // max-width: 78rem;
      max-height: 40rem;
      height: 40rem;
      object-fit: contain;
      margin: 5rem;
    }

    .left_img {
      position: absolute;
      width: auto;
      left: 0;
    }

    .right_img {
      width: auto;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}
@media screen and (max-width: 1300px) {
  .honor_info_div {
    // flex-direction: column;
    .honor_left_div {
      // width:100%;
      height: auto;
      margin: 5rem;

      .left_top_item_div {
        img {
          width: 2rem;
          height: 2rem;
        }

        .left_num {
          font-size: 2rem;
          margin: 0 1rem;
        }

        .left_p {
          font-size: 2rem;
        }
      }

      .left_bottom_item_div {
        margin: 2rem 0;

        img {
          width: 10rem;
          height: 10rem;
        }

        p {
          font-size: 2rem;
          width: 10rem;
        }
      }
    }

    .honor_right_div {
      // width:100%;
      margin: 2rem 5rem 2rem 0;

      .left_img {
        width: 5rem;
        height: 5rem;
      }

      .right_img {
        width: 5rem;
        height: 5rem;
      }

      .center_img {
        max-width: 40rem;
        margin: 3rem;
      }
    }
  }
}
@media screen and (max-width: 1000px) {
  .home_banner {
    height: 45vw;
    object-fit: cover;
  }

  .honor_div .content {
    width: 90%;
  }

  .honor_div {
  }

  .honor_info_div {
    // flex-direction: column;
    .honor_left_div {
      // width:100%;
      height: auto;
      margin: 5rem 2rem;

      .left_top_item_div {
        img {
          width: 2rem;
          height: 2rem;
        }

        .left_num {
          font-size: 2rem !important;
          margin: 0 1rem !important;
        }

        .left_p {
          font-size: 2rem  !important;
        }
      }

      .left_bottom_item_div {
        margin: 2rem 0;

        img {
          width: 10rem;
          height: 10rem;
        }

        p {
          font-size: 2rem;
          width: 10rem;
        }
      }
    }

    .honor_right_div {
      // width:100%;
      margin: 2rem 2rem 2rem 0;

      .left_img {
        width: 3rem;
        height: 3rem;
      }

      .right_img {
        width: 3rem;
        height: 3rem;
      }

      .center_img {
        max-width: 15rem;
        margin: 3rem;
        height:auto;
      }
    }
  }
}

@media screen and (min-width: 1950px) {
  .honor_info_div {
    .honor_right_div {
      .center_img {
        height: 50rem;
        max-height: 50rem;
      }
    }
  }
}
</style>
