<template>
  <div>
    <div class="home_banner">
      <img :src="topImg" />
      <div class="about_title_div">
        <p class="little_title">致力于——</p>
        <p class="mid_title">为用户打造高效的消毒室空间</p>
        <!-- <p class="mid_title">消毒室空间</p> -->
      </div>
    </div>
    <div class="about_div2">
      <p class="head_title">我们的产品</p>
      <ShowLine></ShowLine>

      <div class="product_type_div">
        <p
          class="click_more_white"
          @click="setDeviceType('deviceone')"
          :class="{ active: devicePath === 'deviceone' }"
        >
          &emsp;灭菌设备&emsp;
        </p>
        <p
          class="click_more_white"
          @click="setDeviceType('devicetwo')"
          :class="{ active: devicePath === 'devicetwo' }"
        >
          &emsp;清洗设备&emsp;
        </p>
        <p
          class="click_more_white"
          @click="setDeviceType('devicethree')"
          :class="{ active: devicePath === 'devicethree' }"
        >
          灭菌辅助器械
        </p>
      </div>
      <div class="product_list_div" v-show="devicePath != 'devicethree'">
        <el-row :gutter="10">
          <el-col
            :xs="11"
            :sm="11"
            :md="11"
            :lg="11"
            :xl="11"
            class="product_item_list"
          >
            <div
              v-for="item1 in List1"
              :key="item1.id"
              class="product_item"
              @click="scrollNavto('/CaseDetail/id=' + item1.id)"
            >
              <img :src="item1.cover" />
              <p class="product_title">{{ item1.name }}</p>
            </div>
          </el-col>
          <el-col :xs="1" :sm="1" :md="1" :lg="1" :xl="1">
            <div
              v-for="item1 in List1"
              :key="item1.id"
              class="line"
              v-show="List.length > 2"
            ></div>
          </el-col>
          <el-col
            :xs="11"
            :sm="11"
            :md="11"
            :lg="11"
            :xl="11"
            class="product_item_list2"
          >
            <div
              v-for="(item, index2) in List2"
              :key="index2"
              class="product_item"
              @click="scrollNavto('/CaseDetail/id=' + item.id)"
            >
              <img :src="item.cover" />
              <p class="product_title">{{ item.name }}</p>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="product_three_div" v-show="devicePath === 'devicethree'">
        <div class="product_list_div2">
          <div class="device_type_div">
            <div class="device_type_name_div">
              <p>封&emsp;口&emsp;机</p>
            </div>
            <div class="swiper_father">
              <div class="swiper-container swiper mySwiper1">
                <div class="swiper-wrapper">
                  <div
                    class="swiper-slide"
                    v-for="item in ListThree1"
                    :key="item.name"
                    @click="scrollNavto('/CaseDetail/id=' + item.id)"
                  >
                    <div class="device_item_div">
                      <img class="swiper_img" :src="item.img" />
                      <p class="swiper_name">{{ item.name }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper_btn_div" v-show="ListThree1.length > 3">
                <div class="swiper-button-prev swiper-button-prev1"></div>
                <div class="swiper-button-next swiper-button-next1"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="product_list_div2">
          <div class="device_type_div">
            <div class="device_type_name_div">
              <p>供&emsp;水&emsp;宝</p>
            </div>
            <div class="swiper_father">
              <div class="swiper-container swiper mySwiper2">
                <div class="swiper-wrapper">
                  <div
                    class="swiper-slide"
                    v-for="item in ListThree2"
                    :key="item.name"
                    @click="scrollNavto('/CaseDetail/id=' + item.id)"
                  >
                     <div class="device_item_div">
                      <img class="swiper_img" :src="item.img" />
                      <p class="swiper_name">{{ item.name }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper_btn_div" v-show="ListThree2.length > 3">
                <div class="swiper-button-prev swiper-button-prev2"></div>
                <div class="swiper-button-next swiper-button-next2"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="product_list_div2">
          <div class="device_type_div">
            <div class="device_type_name_div">
              <p>注&emsp;油&emsp;机</p>
            </div>
            <div class="swiper_father">
              <div class="swiper-container swiper mySwiper3">
                <div class="swiper-wrapper">
                  <div
                    class="swiper-slide"
                    v-for="item in ListThree3"
                    :key="item.name"
                    @click="scrollNavto('/CaseDetail/id=' + item.id)"
                  >
                     <div class="device_item_div">
                      <img class="swiper_img" :src="item.img" />
                      <p class="swiper_name">{{ item.name }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper_btn_div" v-show="ListThree3.length > 3">
                <div class="swiper-button-prev swiper-button-prev3"></div>
                <div class="swiper-button-next swiper-button-next3"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="product_list_div2">
          <div class="device_type_div">
            <div class="device_type_name_div">
              <p>蓝&emsp;匣&emsp;子</p>
            </div>
            <div class="swiper_father">
              <div class="swiper-container swiper mySwiper4">
                <div class="swiper-wrapper">
                  <div
                    class="swiper-slide"
                    v-for="item in ListThree4"
                    :key="item.name"
                    @click="scrollNavto('/CaseDetail/id=' + item.id)"
                  >
                     <div class="device_item_div">
                      <img class="swiper_img" :src="item.img" />
                      <p class="swiper_name">{{ item.name }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper_btn_div" v-show="ListThree4.length > 3">
                <div class="swiper-button-prev swiper-button-prev4"></div>
                <div class="swiper-button-next swiper-button-next4"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShowLine from "../components/ShowLine.vue";
export default {
  data() {
    return {
      path: "/Case",
      devicePath: "deviceone",
      topImg:
        "https://xiecheng-imgs.oss-cn-hangzhou.aliyuncs.com/20211209/17a7a68f8c084d4dacc542754b56bd06.jpg",
      List: [
        {
          id: "",
          logo: "",
          title: "",
          titleTwo: "",
          cover: "",
          simple: "",
        },
      ],
      List1: [],
      List2: [],
      ListThree1: [],
      ListThree2: [],
      ListThree3: [],
      ListThree4: [],
    };
  },
  components: { ShowLine },
  watch: {
    $route(to, from) {
      console.log(to.path);
      if (to.path === "/Case") {
        var param = this.$route.query.param;
        this.path = param;
        console.log("param:" + param);
        if (param === "top") {
          window.scrollTo(0, 0);
        } else {
          this.navtoPartPath(this.path);
        }
      }
    },
    ListThree1() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.ProductList1();
        }, 300);
      });
    },
    ListThree2() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.ProductList2();
        }, 300);
      });
    },
    ListThree3() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.ProductList3();
        }, 300);
      });
    },
    ListThree4() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.ProductList4();
        }, 300);
      });
    },
  },
  mounted() {
    this.getCaseBanner(this.devicePath);
    // this.ProductList1();
  },
  methods: {
    ProductList1() {
      this.$nextTick(() => {
        var swiper = new Swiper(".mySwiper1", {
          slidesPerView: "auto",
          navigation: {
            nextEl: ".swiper-button-next1",
            prevEl: ".swiper-button-prev1",
          },
        });
      });
    },
    ProductList2() {
      this.$nextTick(() => {
        var swiper = new Swiper(".mySwiper2", {
          slidesPerView: "auto",
          navigation: {
            nextEl: ".swiper-button-next2",
            prevEl: ".swiper-button-prev2",
          },
        });
      });
    },
    ProductList3() {
      this.$nextTick(() => {
        var swiper = new Swiper(".mySwiper3", {
          slidesPerView: "auto",
          navigation: {
            nextEl: ".swiper-button-next3",
            prevEl: ".swiper-button-prev3",
          },
        });
      });
    },
    ProductList4() {
      this.$nextTick(() => {
        var swiper = new Swiper(".mySwiper4", {
          slidesPerView: "auto",
          navigation: {
            nextEl: ".swiper-button-next4",
            prevEl: ".swiper-button-prev4",
          },
        });
      });
    },
    setDeviceType(type) {
      this.devicePath = type;
      this.getCaseBanner(type);
    },
    navtoPartPath(path) {
      setTimeout(() => {
        document.getElementById(path).scrollIntoView();
      }, 500);
    },
    getCaseBanner(path) {
      let that = this;
      // let path = that.devicePath;
      this.$axios
        .get("app/" + path + "/list", {
          params: {
            page: 0,
            limit: 1000,
          },
        })
        .then(function (response) {
          console.log(response);
          var status = response.data.code;
          if (status === 0) {
            if (that.devicePath === "devicethree") {
              that.ListThree1.splice(0, that.ListThree1.length);
              that.ListThree2.splice(0, that.ListThree2.length);
              that.ListThree3.splice(0, that.ListThree3.length);
              that.ListThree4.splice(0, that.ListThree4.length);
              that.List = response.data.page.list;
              for (var ss = 0; ss < that.List.length; ss++) {
                console.log(that.List[ss]);
                console.log("ss=" + ss);
                var ii = that.List[ss];
                if (ii.deviceType === "封口机") {
                  that.ListThree1.push(ii);
                } else if (ii.deviceType === "供水宝") {
                  that.ListThree2.push(ii);
                } else if (ii.deviceType === "注油机") {
                  that.ListThree3.push(ii);
                } else if (ii.deviceType === "蓝匣子") {
                  that.ListThree4.push(ii);
                }
              }
            } else {
              that.List1.splice(0, that.List1.length);
              that.List2.splice(0, that.List2.length);
              that.List = response.data.page.list;

              for (var ss = 0; ss < that.List.length; ss++) {
                console.log(that.List[ss]);
                console.log("ss=" + ss);
                if ((ss + 1) % 2 != 0) {
                  that.List1.push(that.List[ss]);
                } else {
                  that.List2.push(that.List[ss]);
                }
              }
            }

            // console.log(List);
            // sessionStorage.setItem("input", "null");
          }
          // console.log("that.List");
        })
        .catch((err) => console.log(err));
    },

    navto(path) {
      this.$router.push(path);
    },
    scrollNavto(path) {
      this.$router.push({
        path: `${path}`,
        query: { param: this.devicePath },
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped>
.home_banner {
  width: 100%;
  display: block;
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }
}

.swiper_btn_div {
  margin-top: 1rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  margin-right: 20rem;
}

.swiper-button-next {
  background: rgb(191, 192, 192);
  margin-left: 6rem;
  color: white;
  position: relative;
  height: 3rem;
  width: 9rem;
  outline: none;
}

.swiper-button-prev {
  position: relative;
  background: rgb(191, 192, 192);
  color: white;
  height: 3rem;
  width: 9rem;
  outline: none;
}

.swiper-button-disabled {
  pointer-events: auto;
  opacity: 1;
}

.swiper-button-prev:hover {
  opacity: 1;
  background: var(--textBlue) !important;
}

.swiper-button-next:hover {
  opacity: 1;
  background: var(--textBlue) !important;
}

.swiper-button-prev:after {
  font-size: 2rem;
}

.swiper-button-next:after {
  font-size: 2rem;
}

.product_three_div {
  width: 70%;
  margin: auto;
}

.product_list_div2 {
  border-radius: 1rem;
  width: 100%;
  overflow: hidden;
  margin: 7rem 0 0 0;
}

.device_type_div {
  display: flex;
  flex-direction: row;
}

.device_type_name_div {
  z-index: 2;
  background-color: var(--textBlue);
  height: 45rem;

  p {
    margin: 0 2rem;
    height: 100%;
    color: white;
    font-size: 3.6rem;
    font-family: bold;
    writing-mode: tb-rl;
  }
}

.swiper_father {
  position: relative;
  // padding: 4rem 8rem 3rem 8rem;
  width: 100%;
  background-color: rgb(244, 245, 245);
}

.swiper {
  margin: 2rem 6rem 1rem 6rem;
  width: 100%;
  // height: 30rem;
  background-color: rgb(244, 245, 245);
}

.swiper-wrapper {
  width: 100%;
  background-color: rgb(244, 245, 245);
}

.swiper-slide {
  padding: 2rem;
  border-radius: 0.5rem;
  // height: 30rem;
  width: 30% !important;
  text-align: center;
  font-size: 18px;
  background-color: rgb(244, 245, 245);
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.device_item_div:hover {
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.4);
  transform: translateY(-1rem);
  transition: all 0.4s;
}

.swiper-slide img {
  width: 25rem;
  height: auto;
  display: block;
  object-fit: cover;
}

.device_item_div {
  border-radius: 0.5rem;
  // padding:1rem;
  transition: all 0.4s;
  height: 30rem;
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper_img {
}

.swiper_name {
  margin: 0 0 1rem 0;
  font-size: 2rem;
  color: rgb(76, 73, 72);
  font-family: regular;
}

.head_title {
  font-size: 4.8rem;
  font-family: bold;
  color: var(--textBlue);
  margin: 0 0 3rem;
}

.about_title_div {
  margin: -20rem 0 10rem 0;
  position: absolute;
  width: 30vw;
  padding: 5rem 10rem;
  background-color: var(--topTitleBlue);

  .little_title {
    color: white;
    font-size: 5rem;
    font-family: light;
    margin: 0 5rem;
    text-align: left;
    letter-spacing: 0.5rem;
  }

  .mid_title {
    text-align: left;
    color: white;
    font-size: 5rem;
    font-family: medium;
    margin: 0 5rem;
    letter-spacing: 0.5rem;
  }
}

.about_div2 {
  background: white;
  padding: 12rem 0;
}

.product_type_div {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 80%;
  margin: auto;
}

.click_more_white {
  bottom: 0;
  left: 0;
  cursor: pointer;
  border: 1px solid var(--textBlue);
  width: fit-content;
  height: 3rem;
  background: white;
  color: var(--textBlue);
  font-size: 4rem;
  padding: 2rem 4rem;
  border-radius: 10rem;
  line-height: 3rem;
  float: left;
  font-family: bold;
}

// .click_more_white:hover {
// background: var(--textBlue);
// color: white;
// }
.active {
  background: var(--textBlue);
  color: white;
}

.product_list_div {
  width: 70%;
  margin: 5rem auto;
}

.line {
  height: 70vh;
  width: 1px;
  border-left: 2px dashed var(--gary7F7F7F);
  overflow: hidden;
  margin: auto;
  margin-top: 2px;
}

.product_item_list {
  div:nth-child(2) {
    // border-left: 2px dashed var(--gary7F7F7F);
    border-top: 2px dashed var(--gary7F7F7F);
  }
}

.product_item_list2 {
  div:first-child {
    border-bottom: 2px dashed var(--gary7F7F7F);
  }

  div:nth-child(3) {
    // border-left: 2px dashed var(--gary7F7F7F);
    border-top: 2px dashed var(--gary7F7F7F);
  }
}

.product_item {
  margin: auto;
  // width: 50rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5rem;
  transition: all 0.4s;

  img {
    padding: 3rem;
    transition: all 0.4s;
  }

  .product_title {
    bottom: 0;
    left: 0;
    cursor: pointer;
    border: 1px solid var(--textBlue);
    width: fit-content;
    // height: 3rem;
    background: white;
    color: var(--textBlue);
    font-size: 3.2rem;
    padding: 1rem 2rem;
    border-radius: 10rem;
    line-height: 3rem;
    float: left;
    font-family: regular;
  }
}

.product_item:hover {
  img {
    box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.4);
    transform: translateY(-1rem);
    transition: all 0.4s;
  }

  .product_title {
    background: var(--textBlue);
    color: white;
  }
}

@media screen and (max-width: 1400px) {
  .line {
    height: 45vh;
  }
}

@media screen and (max-width: 1400px) {
  .about_div2 {
    padding: 20rem 0 10rem;
  }

  .about_title_div {
    // width: auto;
    margin: -10rem 0 10rem 0;

    p {
      padding: 0;
      font-size: 4rem;
    }

    .little_title {
      margin: 0;
      font-size: 4rem;
    }

    .mid_title {
      margin: 0;
      font-size: 4rem;
    }
  }

  .product_item {
    img {
      width: 100%;
      height: 100%;
    }

    .product_title {
      font-size: 2rem;
      height: auto;
    }
  }
}

@media screen and (max-width: 1000px) {
  .home_banner {
    height: 45vw;
    object-fit: cover;
  }
.product_three_div{
  width:90%;
}
.swiper{
  margin:1rem 2rem;
}
.swiper-slide{
  width: 60% !important;
}
.swiper-slide img {
  width: 15rem;
  height: auto;
  display: block;
  object-fit: cover;
}

.device_type_name_div {
  padding:0 2rem;
  p {
    margin: 0 3rem 0 0;
    height: 100%;
    color: white;
    font-size: 3.6rem;
    font-family: bold;
    writing-mode: tb-rl;
  }
}

  .about_title_div {
    width: 60vw;
    margin: -7rem 0 10rem 0;
    padding:1rem 3rem;

    p {
      padding: 0;
      font-size: 3rem;
    }

    .little_title {
      margin: 0;
      font-size: 3rem;
    }

    .mid_title {
      margin: 0;
      font-size: 3rem;
    }
  }

  .about_div2 {
    padding: 10rem 0 10rem;
  }

  .product_type_div {
    width: 100%;
  }

  .click_more_white {
    font-size: 2rem;
    padding:1rem 2rem;
  }

  .product_list_div {
    width: 100%;
  }

  .product_item {
    width: 14rem;
    padding:0;

    img {
      padding:1rem;
      width: 100%;
      height: 100%;
    }

    .product_title {
      width:14rem;
      font-size: 1rem;
      height: auto;
      padding:0 1rem;
    }
  }
  .product_item:hover{
    transform: none;
    // margin:1rem auto;
  }

  .line {
    height: 30vh;
  }
}
</style>
