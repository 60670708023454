import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/components/Home'
import Index from '@/components/Index'
import Index2 from '@/components/Index2'
import Brand from '@/components/Brand'
import Case from '@/components/Case'
import News from '@/components/News'
import About from '@/components/About'
import CallUs from '@/components/Callus'
import CaseDetail from '@/components/CaseDetail'
import NewsDetail from '@/components/NewsDetail'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '协成',
    component: Index2,
    children: [{
      path: '/',
      component: Home,
      name: '协成'
    },
    ,
    {
      path: '/Brand',
      component: Brand,
      name: ''
    },
    {
      path: '/Case',
      component: Case,
      name: ''
    },
    {
      path: '/News',
      component: News,
      name: ''
    },
    {
      path: '/About',
      component: About,
      name: ''
    },
    {
      path: '/NewsDetail/:id',
      component: NewsDetail,
      name: ''
    },
    {
      path: '/CaseDetail/:id',
      component: CaseDetail,
      name: ''
    },{
      path: '/CallUs',
      component: CallUs,
      name: ''
    },
  ]
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
